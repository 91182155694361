/* eslint-disable react-hooks/exhaustive-deps */
import httpAuth, { httpAuthNoHeaders } from './http';
import ActaAuthUtils from '../utils';
import { TagClass } from '../apps/tags/models/tag';
import { StaffClass } from '../apps/staff/models/staff';
import { SectorClass } from '../apps/sectors/models/sector';
import { StructureClass } from '../apps/structures/models/structure';
import { OfficeClass } from '../apps/offices/models/office';
import { MenuClass } from '../apps/menus/models/menu';
import { ActaUser } from '../apps/user/models/user';
import { PageEvaluationClass } from 'pa_kit/apps/page_evaluation/models/page_evaluation';
import { GDPRRegistryClass } from 'pa_kit/apps/gdpr_registry/models/gdpr_registry';


class AuthApiService {

    //CSRF Token
    async csrf_token() {
        let result = await httpAuth.get('get_csrf_token');
        return result.data;
    }

    //Login
    async login(formData) {
        let result = await httpAuth.post('token/', {...formData });
        return result.data;
    }

    //Logout
    async logout() {
        let result = await httpAuth.post('logout/blacklist/', { refresh_token: ActaAuthUtils.getRefreshToken() });
        return result.data;
    }

    //Get User
    async getUser() {
        try{
            let result = await httpAuth.get('userinfo/');     
            return result.data[0];
        }catch(e){
            return null;
        }
    }

    //Get User With Attributes
    async getUserWithAttributes() {
        try{
            let result = await httpAuth.get('userinfo/full/');     
            return result.data[0];
        }catch(e){
            return null;
        }
    }

    //Get User
    async getUsers() {
        let result = await httpAuth.get('users/');
        return result.data.map((data) => new ActaUser(data));
    }

    //Get User
    async getUserData(id) {
        let result = await httpAuth.get('users/' + id);
        return result.data;
    }

    //Get Website Settings
    async getWebsiteSettings() {
        let result = await httpAuth.get('website_settings/');
        return result.data[0];
    }

    //Edit Website Settings
    async editWebsiteSettings(id, formData) {
        let result = await httpAuth.patch('website_settings/' + id + '/', {...formData });
        return result;
    }

    //Get Theme
    async getTheme(name) {
        let result = await httpAuth.get('theme/' + name + '/');
        return result.data;
    }

    //Edit Theme
    async editTheme(name, formData) {
        let result = await httpAuth.patch('theme/' + name + '/', {...formData });
        return result;
    }

    // Get Organization Info
    async getOrganization() {
        let result = await httpAuth.get('organization/');
        return result.data[0];
    }

    // Edit Organization Info
    async editOrganization(id, formData) {
        let result = await httpAuth.patch('organization/' + id + '/', {...formData });
        return result;
    }

    //Get Tags
    async getTags() {
        let result = await httpAuth.get('tags/');       
        return result.data.map((data) => new TagClass(data));
    }

    //Get Tag
    async getTag(slug) {
        let result = await httpAuth.get('tags/' + slug);       
        return result.data;
    }

    //Create Tag
    async createTag(formData) {
        let result = await httpAuth.post('tags/', {...formData });
        return result;
    }

    //Edit Tag
    async editTag(slug, formData) {
        let result = await httpAuth.patch('tags/' + slug + '/', {...formData });
        return result;
    }

    //Delete Event
    async deleteTag(slug) {
        let result = await httpAuth.delete('tags/' + slug + '/');
        return result;
    }

    //Get Menu
    async getMenu(id) {
        let result = await httpAuth.get('menu/' + id);       
        return result.data;
    }

    //Get Menu List
    async getMenus() {
        let result = await httpAuth.get('menu/');       
        return result.data.map((data) => new MenuClass(data));
    }

    //Create Menu
    async createMenu(formData) {
        let result = await httpAuth.post('menu/', {...formData });
        return result;
    }

    //Edit Menu
    async editMenu(id, formData) {
        let result = await httpAuth.patch('menu/' + id + '/', {...formData });
        return result;
    }

    //Delete Menu
    async deleteMenu(id) {
        let result = await httpAuth.delete('menu/' + id + '/');
        return result;
    }

    //Get Structures
    async getStructures() {
        let result = await httpAuth.get('structures/');       
        return result.data.map((data) => new StructureClass(data));
    }

    //Get Structure
    async getStructure(id) {
        let result = await httpAuth.get('structures/' + id);       
        return result.data;
    }

    //Create Structure
    async createStructure(formData) {
        let result = await httpAuth.post('structures/', {...formData });
        return result;
    }

    //Edit Structure
    async editStructure(id, formData) {
        let result = await httpAuth.patch('structures/' + id + '/', {...formData });
        return result;
    }

    //Delete Structure
    async deleteStructure(id) {
        let result = await httpAuth.delete('structures/' + id + '/');
        return result;
    }

    //Get Staff
    async getStaff() {
        let result = await httpAuth.get('staff/');       
        return result.data.map((data) => new StaffClass(data));
    }

    //Get Staff Profile
    async getStaffProfile(id) {
        let result = await httpAuth.get('staff/' + id);       
        return result.data;
    }

    //Create Staff
    async createStaff(formData) {
        let result = await httpAuth.post('staff/', {...formData });
        return result;
    }

    //Edit Staff
    async editStaff(id, formData) {
        let result = await httpAuth.patch('staff/' + id + '/', {...formData });
        return result;
    }

    //Delete Staff
    async deleteStaff(id) {
        let result = await httpAuth.delete('staff/' + id + '/');
        return result;
    }

    //Get Sectors
    async getSectors() {
        let result = await httpAuth.get('sectors/');       
        return result.data.map((data) => new SectorClass(data));
    }

    //Get Sector
    async getSector(id) {
        let result = await httpAuth.get('sectors/' + id);       
        return result.data;
    }

    //Create Sector
    async createSector(formData) {
        let result = await httpAuth.post('sectors/', {...formData });
        return result;
    }   
    
    //Edit Sector
    async editSector(id, formData) {
        let result = await httpAuth.patch('sectors/' + id + '/', {...formData });
        return result;
    }

    //Delete Sector
    async deleteSector(id) {
        let result = await httpAuth.delete('sectors/' + id + '/');
        return result;
    }

    //Get Offices
    async getOffices() {
        let result = await httpAuth.get('offices/');       
        return result.data.map((data) => new OfficeClass(data));
    }

    //Get Office
    async getOffice(id) {
        let result = await httpAuth.get('offices/' + id);       
        return result.data;
    }

    //Create Office
    async createOffice(formData) {
        let result = await httpAuth.post('offices/', {...formData });
        return result;
    } 

    //Edit Office
    async editOffice(id, formData) {
        let result = await httpAuth.patch('offices/' + id + '/', {...formData });
        return result;
    }

    //Delete Office
    async deleteOffice(id) {
        let result = await httpAuth.delete('offices/' + id + '/');
        return result;
    }

    //Upload File on Auth Server
    async uploadFileAuth(file, source) {
        const formData = new FormData();

        switch(source) {            

            case "tinymce_tag":
                formData.append("source", "tinymce_tag");
                break;            

            default:
                formData.append("source", "standard");
        }
        
        formData.append("file", file);

        const filenameHeaderValue = 'inline;filename=' + encodeURIComponent(file.name);

        const result = await httpAuth.put('/file/upload/', formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': filenameHeaderValue,
                    "Access-Control-Allow-Origin": "*"
                }
            }
        );
        return result.data;
    }

    //Create Page Evaluation
    async createPageEvaluation(formData) {
        let result = await httpAuth.post('page_evaluation/', {...formData });
        return result;
    }

    //Get Page Evaluation by Cookie Token
    async getPageEvaluationByCookieToken(token) {
        let result = await httpAuth.get('page_evaluation/cookie_token/' + token);
        return result.data;
    }

    //Check Page Evaluation by IP Address
    async checkPageEvaluationByIPAddress() {
        let result = await httpAuth.get('page_evaluation/ip_address/');
        return result.data;
    }

    //Get Page Evaluation
    async getPageEvaluation() {
        let result = await httpAuth.get('page_evaluation/');
        return result.data.map((data) => new PageEvaluationClass(data));
    }

    //Get Page Evaluation
    async getPageEvaluationByPath(path) {
        let result = await httpAuth.get('page_evaluation/path/' + path + '/');
        return result.data.map((data) => new PageEvaluationClass(data));
    }

    //Create GDPR Registry
    async createGDPRRegistry(formData) {
        let result = await httpAuth.post('gdpr_registry/', {...formData });
        return result;
    }

    //Get GDPR Registry
    async getGDPRRegistry() {
        let result = await httpAuth.get('gdpr_registry/');
        return result.data.map((data) => new GDPRRegistryClass(data));
    }

    //Get Embedded Header
    async getEmbeddedHeader(url) {
        const result = await httpAuthNoHeaders.get(url);
        return result.data;
    }

    //Get Embedded Footer
    async getEmbeddedFooter(url) {
        const result = await httpAuthNoHeaders.get(url);
        return result.data;
    }
}

let authApiService = new AuthApiService();
export default authApiService;
